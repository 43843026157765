<template>
  <div class="main-content">
    <div class="main-div">
      <div class="top">
        <div class="contact-image">
          <img src="/img/data-processing-icon.a09c868c.svg" alt="" />
          <p>User Stats</p>
        </div>
      </div>

      <div class="start-table text-center mb-5">
        <div v-if="currentlyApiLoading">
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
          <div class="skeleton-loder skeleton-table-h"></div>
        </div>
        <div v-if="this.noData" class="nomoredata">
          <img src="../../../../../assets/images/nomoredata.png" />
          <h5 class="mt-4">
            No contact here to<br />
            show
          </h5>
        </div>
        <table
          v-if="!currentlyApiLoading && this.auditPhoneCost.length !== 0"
          class="first-tables table-new table-ceter-align"
        >
          <thead>
            <tr class="Head_table">
              <th class="table_head">Endpoint</th>
              <th class="table_head">Source</th>
              <th class="table_head">First name</th>
              <th class="table_head">Last Name</th>
              <th class="table_head">Username</th>
              <th class="table_head">Status</th>
              <th class="table_head">Credits</th>
              <th class="table_head">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) in paginatedData" :key="index">
              <td class="R1">{{ record.endPoint }}</td>
              <td class="R1">{{ record.inputData.searchRequest.source }}</td>
              <td class="R1">{{ record.inputData.searchRequest.firstName }}</td>
              <td class="R1">{{ record.inputData.searchRequest.lastName }}</td>
              <td class="R1">{{ record.inputData.searchRequest.username }}</td>
              <td
                class="status-td"
                v-bind:class="{
                  completed: record.status == 'Successful',
                  duplicate: record.status == 'error',
                  in_progress: record.status == 'In Progress',
                }"
              >
                <span>{{ record.status }}</span>
              </td>
              <td class="R1">{{ record.credits }}</td>
              <td class="R1">{{ formatCreationDate(record.createdAt) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="nomoredata">
          <img src="../../../../../assets/images/nomoredata.png" />
          <h5>No more Data</h5>
        </div>  -->
      </div>
      <div v-if="this.auditPhoneCost.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="auditPhoneCost.length"
          @change="paginatedData"
          :per-page="10"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_PHONE_ENRICHED_LOGS } from "../../../../store/constant/actions.type";
import { loadingService } from "../../services/loading.service";
export default {
  name: "userStats",
  data() {
    return {
      auditPhoneCost: [],
      currentlyApiLoading: true,
      pageSize: 10,
      currentPage: 1,
      noData: false,
    };
  },
  created() {},
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.auditPhoneCost.slice(start, end);
    },
  },
  methods: {
    // totalPages() {
    //   return Math.ceil(this.auditPhoneCost.length / this.pageSize);
    // },
    // nextPage() {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage += 1;
    //   }
    // },
    // previousPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage -= 1;
    //   }
    // },
    formatCreationDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0];
    },
  },
  beforeMount() {},
  mounted() {
    loadingService.setloader(true);
    this.$store
      .dispatch(GET_PHONE_ENRICHED_LOGS)
      .then((response) => {
        if (response) {
          this.currentlyApiLoading = false;
          loadingService.setloader(false);
          this.auditPhoneCost = response.auditPhoneCost;
          if (this.auditPhoneCost.length == 0) {
            this.noData = true;
          }
          this.auditPhoneCost.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            // Compare the dates in descending order
            return dateB - dateA;
          });
        } else {
          loadingService.setloader(false);
          this.flashMessage.error({
            title: "Error",
            message: response.message,
          });
        }
      })
      .catch((err) => {
        this.currentlyApiLoading = false;
        loadingService.setloader(false);
        console.log("catch err", err);
        this.flashMessage.error({
          title: "Error",
          message: err.message,
        });
      });
  },
};
</script>

<style>
.links-button a {
  display: block;
  width: 100%;
  max-width: 200px;
}

.links-button {
  text-align: center;
  padding: 0;
  height: 79vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.links-button a button {
  margin: 0;
  width: 100%;
}

.main-div {
  background: #fff;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
}

.top {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
}

.top p {
  color: #000;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}

.table-new tr:nth-child(odd) {
  background-color: #f6f6f6;
}

.table_head {
  background: #3f8aff;
  color: #fff !important;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  width: 8% !important;
}

.first-tables {
  width: 100%;
  line-height: 45px;
  color: #ffffff;
}

.start-table {
  margin-top: 24px;
  overflow-x: auto;
}

.table-new tr td {
  line-height: 25px;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.table-new tr td.status-td span {
  border-radius: 50px;
  display: inline-block;
  padding: 2px 9px 0;
  font-size: 12px;
  font-weight: 700;
}

td.completed span {
  color: #5bb645;
  border: 1px solid #5bb645;
}

td.duplicate span {
  border: 1px solid #da4e39;
  color: #da4e39;
}
.nomoredata {
  display: block;
  padding: 50px 0;
}
.nomoredata img {
  height: 100px;
}
.skeleton-loder {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  will-change: transform;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: loading 2.6s linear infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.skeleton-table-h {
  height: 40px;
}
</style>
